import {
  IAPITokenGenerationParams,
  IAppPostDataTokenGeneration,
  IAPITokenGenerationResponse,
  IDeleteResponse,
  IApiToken,
} from 'spekit-types';

import {api} from '../api';

export const getTokens = async (params?: IAPITokenGenerationParams) => {
  return api
    .get<IAPITokenGenerationResponse>('/api/v1/user/apitoken', {params})
    .then(({data}) => data);
};

export const deleteToken = (tokenid: string) => {
  return api
    .delete<IDeleteResponse>(`/api/v1/user/apitoken/${tokenid}/`)
    .then(({data}) => data);
};

export const revokeAllTokens = () => {
  return api
    .delete<IDeleteResponse>(`/api/v1/user/apitoken/destroy_all/`)
    .then(({data}) => data);
};

export const postTokenGeneration = (data: IAppPostDataTokenGeneration) => {
  return api.post<IApiToken>(`/api/v1/user/apitoken/`, data).then(({data}) => data);
};
